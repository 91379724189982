<template>
  <div class="profile-gift-container">
    <v-container fluid v-if="headerProposals.length > 0">
      <component
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>
    <v-container fluid class="pa-0">
      <CategoryTitle :category="category" />
    </v-container>
    <v-container fluid class="pa-0">
      <GiftList
        v-if="filteredAutoAssignedGifts.length > 0"
        :giftList="filteredAutoAssignedGifts"
        :title="$t('profile.gifts.coupon')"
        @reload="reload"
      />
    </v-container>
    <v-container fluid class="pa-0">
      <GiftList
        v-if="filteredManualAssignedGifts.length > 0"
        :giftList="filteredManualAssignedGifts"
        :title="$t('profile.gifts.advantages')"
        @reload="reload"
      />
    </v-container>
    <v-container fluid class="pa-0" v-if="footerProposals.length > 0">
      <component
        :is="footerMode"
        :title="footerTitle"
        :proposals="footerProposals"
        paginationClass="pagination-footer"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>

    <!-- Invita un amico -->
  </div>
</template>
<style lang="scss">
.profile-gift-container {
  .invite-card {
    height: 210px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: auto;
    }
    .v-image {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import GiftList from "@/components/gift/GiftList.vue";
import login from "~/mixins/login";
import category from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapProposalComponent } from "@/commons/service/ebsn";
import userService from "~/service/userService";
import get from "lodash/get";

export default {
  name: "ProfileGift",
  mixins: [category, deliveryReactive, login],
  components: { CategoryTitle, GiftList },
  data() {
    return {
      autoAssignedGifts: [],
      manualAssignedGifts: []
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    ...mapProposalComponent({
      header: "header",
      footer: "footer"
    }),
    filteredAutoAssignedGifts() {
      return this.autoAssignedGifts.filter(
        gift =>
          !get(
            gift,
            "categoryProposal.metaData.category_proposal_type.HIDE_IN_LIST"
          ) == true
      );
    },
    filteredManualAssignedGifts() {
      return this.manualAssignedGifts.filter(
        gift =>
          !get(
            gift,
            "categoryProposal.metaData.category_proposal_type.HIDE_IN_LIST"
          ) == true
      );
    }
  },
  methods: {
    async reload() {
      this.autoAssignedGifts = await userService.listGiftCertificate("auto");
      this.manualAssignedGifts = await userService.listGiftCertificate(
        "manual"
      );
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
